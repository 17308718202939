import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill: Strongman Day`}</p>
    <p>{`Practice each event, then perform each movement for 1:00, resting for
2:00 between events.`}</p>
    <p>{`Sled Push (+90/45) `}<em parentName="p">{`for distance`}</em></p>
    <p>{`Tire Flips `}<em parentName="p">{`for reps`}</em></p>
    <p>{`Yoke Walk (+180/100) `}<em parentName="p">{`for distance`}</em></p>
    <p>{`D Ball Cleans (150/80) `}<em parentName="p">{`for reps`}</em></p>
    <p>{`Farmers Carry (+90/50) `}<em parentName="p">{`for distance`}</em></p>
    <p>{`Log G2OH (+50/empty log) `}<em parentName="p">{`for reps`}</em></p>
    <p>{`Sled Drag (+135/90) `}<em parentName="p">{`for distance`}</em></p>
    <p>{`Score = total reps.  For distance movements 5 feet = 1 rep.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our FREE New Years Day workout will be at 10:30.  Bring a friend!
 All other class times and open gym times are cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 6:00pm class on New Years Eve is cancelled.  There will be no
open gym after 6:00pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      